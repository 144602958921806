import * as React from "react"
import {FaRegLightbulb} from '@react-icons/all-files/fa/FaRegLightbulb';
import Layout from "../layouts/index"
import {useState} from "react";

const IndexPage = () => {
    const [buttonLabel, setButtonLabel] = useState('Licht Aus');
    const [mainClass, setMainClass] = useState('lights-on');

    const lightsOut = () => {
        if (buttonLabel === 'Licht Aus') {
            setButtonLabel('Licht An');
            setMainClass('lights-off');
        } else {
            setButtonLabel('Licht Aus');
            setMainClass('lights-on');
        }
    };

    return (
        <Layout>
            <main className={mainClass}>
                <div className={"container py-4"}>
                    <title>licht-t3</title>
                    <a href={'/impressum'} className={'float-end pb-3 text-decoration-none'}>Impressum</a>
                    <div className="clearfix"/>
                    <div className="row text-center">
                        <h1 className={'display-4'}>
                            Herzlich Willkommen
                        </h1>
                        <p className={'fs-4'}>...in unserer Welt des Lichts und der schönen Dinge.</p>
                        <div className="col-lg-8 mx-auto transparent-bg mt-3">
                            <p className="fs-5">Natürlich können
                                Sie
                                sich in unserem Lichtstudio inspirieren lassen,
                                doch unsere Mission ist, für Sie das richtige Licht zu finden und nicht nur pauschal
                                „hell
                                zu
                                machen“. Licht hat Einfluss auf Stimmung, Wohlbefinden, Gesundheit und sogar die
                                Produktivität
                                am Arbeitsplatz. Wir beraten Sie und planen mit Ihnen ein bedarfsgerechtes und
                                geschmackvolles
                                Beleuchtungskonzept für jeden Raum, egal ob für <a
                                    href={"https://www.uta-von-schenck.de/"} target={"_blank"} className={'link-light'}>Wohnraum,
                                    Gewerbe oder
                                    Industrie</a>.
                                Ergänzend
                                finden Sie bei uns eine Vielzahl von <a
                                    href={'http://www.geschenkoo.de/alle-geschenke.php?knr=lku-37073'} target={"_blank"}
                                    className={'link-light'}>Homeaccessoires</a> und ausgewählte Möbelkollektionen.
                            </p>
                            <p className="fs-5">
                                Dann bleibt uns nur, dass Ihnen bei uns ein Licht aufgeht!
                            </p>
                            <button
                                onClick={lightsOut}
                                className="btn btn-outline-light btn-lg me-3 position-relative btn-absolute-icon-right"
                                type="button">
                                {buttonLabel}
                                <span className={'absolute-icon-right'}><FaRegLightbulb/></span>
                            </button>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default IndexPage
