import React from "react"
import PropTypes from "prop-types"

import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min.js";
//import "@popperjs/core/dist/umd/popper.min.js";
import "../styles/main.scss"

const Layout = ({ children }) => <>{children}</>

Layout.propTypes = {
    children: PropTypes.any,
}

export default Layout